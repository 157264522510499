/* Family: SF Pro Text */
/* Weights: 200, 400, 500, 600, 700, 900 */
/* Styles: normal */
/* Formats: woff2 */

@font-face {
  font-display: swap;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 100 900;
  src: url('~@/styles/fonts/SFNSText.woff2') format('woff2-variations');
}

/* Family: SF Pro Display */
/* Weights: 200, 400, 500, 600, 700, 900 */
/* Styles: normal */
/* Formats: woff2 */

@font-face {
  font-display: swap;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  src: url('~@/styles/fonts/SFNSDisplay.woff2') format('woff2-variations');
}
