
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";

.root {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;

  @include for-desktop {
    padding: 0 53px 0 27px;
  }
}

.mainContentWrapper {
  margin-top: auto;
  margin-bottom: auto;

}

.title {
  font-size: 24px;
  line-height: 29px;
  color: $color-black;
  margin-bottom: 10px;
  font-family: $font-sf-pro-display;
}

.subtitle {
  color: rgba(9, 15, 30, 0.8);
  line-height: 18px;
  margin-bottom: 34px;
}

.copyright {
  margin-top: auto;
}
