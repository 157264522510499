
@import "~@/styles/variables.scss";

.root {
  display: flex;
}

.item {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: $color-white;

  &:last-child {
    margin-right: 0;
  }
}

.telegram {
  composes: item;
  background: linear-gradient(240.2deg, #0fb1d6 0%, #0088cc 61.34%);
}

.whatsApp {
  composes: item;
  background: linear-gradient(89.67deg, #3dd078 0.57%, #089e4e 99.35%);
}

.email {
  composes: item;
  background: linear-gradient(98.44deg, #5e9fff 0%, #004dbd 95.33%);
}

.icon {
  font-size: 18px;
}

.rootFull {
  composes: root;

  .item {
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    margin-right: 5px;
  }
}
