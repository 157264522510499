
@import "~@/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 15px;
}

.link {
  height: 46px;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  color: $color-white;
  font-size: 16px;
  line-height: 1.2;
  word-break: break-word;
  cursor: pointer;
}

.icon {
  font-size: 24px;
  color: $color-white;
  margin-right: 15px;
  flex-shrink: 0;
}

.links {
  margin-bottom: auto;
}

.logoutWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 10px;
}

.logout {
  composes: link;
  border: 0;
  background: transparent;
  padding: 0;
  text-align: left;
  margin-right: 5px;
}

.active {
  color: $color-blue;
  font-weight: bold;

  .icon {
    color: $color-blue;
  }
}
