
@import "~@/styles/variables.scss";

.root {
  color: $color-blue;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.arrow {
  transform: rotate(180deg);
  font-size: 12px;
  margin-right: 4px;
}
