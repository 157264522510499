
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";

.root {
  text-align: right;
}

.name {
  font-size: 13px;
  line-height: 15px;
  color: $color-white;
  font-weight: bold;

  @include for-desktop {
    color: $color-black;
  }
}

.id {
  font-size: 11px;
  line-height: 15px;
  color: rgba($color-gray, .7);
}
