
@import "~@/styles/variables.scss";
@import "~@/styles/mixins.scss";

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.body {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  background-color: $color-black;
  padding: 45px 30px 45px;
  margin-top: auto;
  width: 100%;
  border-radius: 36px 36px 0 0;
  z-index: 2;
}

.content {
  overflow: auto;
}

.overlay {
  background-color: rgba(9, 15, 30, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.close {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
}

.swipeLine {
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  margin: auto;
}

$transition-duration: .3s;

.enterFrom {
  .body {
    opacity: 0;
    transform: translateY(100px);
  }

  .overlay {
    opacity: 0;
  }
}

.enterActive {
  transition: opacity $transition-duration;

  .body {
    transition: opacity $transition-duration, transform $transition-duration;
  }

  .overlay {
    transition: opacity $transition-duration;
  }
}

@include for-desktop {
  .body {
    width: 375px;
    max-height: 80vh;
    border-radius: 20px;
    margin-top: 0;
  }

  .close {
    display: block;
  }

  .swipeLine {
    display: none;
  }
}
