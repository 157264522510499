
.root {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  background-color: #fff;
  padding: 5px 0;
  border-radius: 8px;
  filter: drop-shadow(0px 6px 22px rgba(0, 0, 0, 0.3));

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    height: 9px;
  }
}

.triangle {
  width: 17px;
  height: 8px;
  position: absolute;
  top: -7px;
  right: 0;
  left: 0;
  margin: auto;
}

.enterActive {
  transition: opacity .3s ease;
}

.enterFrom {
  opacity: 0;
}
