
.circle {
  font-size: 25px;
  animation: spin 1000ms infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
