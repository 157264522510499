
@import "~@/styles/mixins.scss";

.header {
  width: 225px;
  flex-shrink: 0;
  border-radius: 20px;
}

.content {
  min-height: 100vh;
  padding-top: $size-header-mobile-height;
  box-shadow: 0 34px 58px -12px rgba(148, 166, 205, 0.2);
  background-color: $color-white;
  overflow: hidden;

  @include for-desktop {
    width: 685px;
    display: flex;
    margin: 0 auto;
    min-height: 0;
    height: 751px;
    padding-top: 0;
    border-radius: 20px;
    overflow: visible;
  }
}

.contentLogin {
  composes: content;

  @include for-desktop {
    height: 402px;
    min-height: 0;
  }
}

.page {
  padding: 20px $size-mobile-container-padding-x 45px;
  max-width: $size-mobile-container-max-width;
  min-height: calc(100vh - #{$size-header-mobile-height});
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include for-desktop {
    padding: 30px;
    max-width: none;
    min-height: 0;
    margin: 0;
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.userInfoDesktop {
  margin-left: auto;
}

.viewWrapper {
  flex-grow: 1;
  position: relative;
}
