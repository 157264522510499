
@import "~@/styles/mixins.scss";

.root {
  background-color: $color-background;
  min-height: 100vh;

  @include for-desktop {
    padding: 35px 0;
    display: flex;
    align-items: center;
  }
}
