
.custom-notification {
  &.success {
    --bg-color: #2fbb68;
  }

  &.info {
    --bg-color: #0855c4;
  }

  &.error {
    --bg-color: #a1adcd;
  }
}
