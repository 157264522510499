// fonts
$font-sf-pro-text: -apple-system, 'SF Pro Text', sans-serif;
$font-sf-pro-display: -apple-system, 'SF Pro Display', 'SF Pro Text', sans-serif;

// colors
$color-black: #090F1E;
$color-gray: #94A6CD;
$color-white: #FFFFFF;
$color-blue: #1A88F3;
$color-green: #2AB764;
$color-red: #F96057;
$color-text: rgba(9, 15, 30, 0.8);
$color-text-light: rgba(92, 114, 163, 0.8);
$color-background: #F4F5FC;

// sizes
$size-header-mobile-height: 66px;
$size-mobile-container-max-width: 425px;
$size-mobile-container-padding-x: 16px;

// breakpoints
$desktop-min-width: 769px;
