
@import "~@/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.nav {
  flex-grow: 1;
  border-bottom: 1px solid rgba(151, 174, 225, 0.2);
}

.info {
  margin-top: 20px;
}

.infoHeader {
  color: $color-white;
  font-size: 14px;
  line-height: 13px;
  margin-bottom: 6px;
}

.infoText {
  color: rgba($color-gray, 0.7);
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 12px;
}

.socials {
  margin-bottom: 30px;
}

.rulesLink {
  color: $color-blue;
}

.copyright {
  margin-top: 10px;
  margin-bottom: 3px;
}
