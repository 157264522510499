
.root {
  position: relative;
  background-color: rgba(151, 174, 225, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;

  &.error {
    border: 1px solid #FC5415
  }
}

.input {
  display: block;
  width: 100%;
  border: none;
  height: 40px;
  padding: 11px 15px;
  font-size: 13px;
  border-radius: 10px;
  background-color: transparent;
  order: 2;

  &::placeholder {
    color: #5C72A3;
  }

  &:focus {
    outline: none;
  }
}

.beforeElement {
  height: 26px;
  padding: 0 6px 0 15px;
  border-right: 1px solid rgba(151, 174, 225, 0.2);
  line-height: 26px;
  order: 1;

  & ~ .input {
    padding-left: 6px;
  }
}

.afterElement {
  height: 26px;
  padding: 0 15px 0 6px;
  border-left: 1px solid rgba(151, 174, 225, 0.2);
  line-height: 26px;
  order: 3;

  & ~ .input {
    padding-right: 6px;
  }
}

.button {
  color: #096DFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  order: 4;
  border: 0;
  background: transparent;
}

.errorMessage {
  margin-top: 4px;
  color: #FC5415;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
