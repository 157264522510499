@import "~normalize.css";
@import "fonts";
@import "variables";

html,
body {
  font-family: $font-sf-pro-text;
  font-size: 14px;
  line-height: 1;
  font-weight: normal;
  color: $color-text-light;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent
}

h1 {
  margin: 0;
}

a {
  text-decoration: none;
}

.rtl {
  direction: rtl;
}
